
const CVArcoPublicidad = () =>  {
    return (
        <div>
            <iframe title="cv" className="doc" src="https://docs.google.com/viewer?srcid=1UZzUqoNip1_RVc8l38J4zLhOUcGQ2gBh&pid=explorer&embedded=true"></iframe>
        </div>
    );
}
export default CVArcoPublicidad

