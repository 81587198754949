import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Hardware  from './pages/Hardware'
import Home  from './pages/Home'
import Quotations  from './pages/Quotations'
import CVShaped from './pages/CV_Shaped'
import CVCapi from './pages/CV_Capi'
import CVBego from './pages/CV_Bego'
import CVBio from './pages/CV_Bio'
import CVLive from './pages/CV_Live'
import CVTeam from './pages/CV_Team'
import CVRappi from './pages/CV_Rappi'
import CVInfi from './pages/CV_Infi'
import CVTailor from './pages/CV_Tailor'
import CVArtisan from './pages/CV_Artisan'
import CVTurntable from './pages/CV_Turntable'
import CVReady from './pages/CV_Ready'
import CVCambioml from './pages/CV_Cambioml'
import CVTypewise from './pages/CV_Typewise'
import CVFurtherai from './pages/CV_Furtherai'
import CVAutarc from './pages/CV_Autarc'
import CVRelari from './pages/CV_Relari'
import CVTrellis from './pages/CV_Trellis'

import CVWorkflow86 from './pages/CV_Workflow86'
import CVCapixai from './pages/CV_Capixai'
import CVDodo from './pages/CV_Dodo'
import CVControlhub from './pages/CV_Controlhub'
import CVLiterably from './pages/CV_Literably'
import CVDreamcraft from './pages/CV_Dreamcraft'
import CVCodecrafters from './pages/CV_Codecrafters'
import CVSyntra from './pages/CV_Syntra'
import CVKaya from './pages/CV_Kaya'
import CVRoeai from './pages/CV_Roeai'
import CVStackai from './pages/CV_Stackai'
import CVReveryai from './pages/CV_Reveryai'
import CVTavus from './pages/CV_Tavus'
import CVHaystack from './pages/CV_Haystack'
import CVSkyways from './pages/CV_Skyways'
import CVDot from './pages/CV_Dot'
import CVStorylane from './pages/CV_Storylane'
import CVVerdn from './pages/CV_Verdn'
import CVTandem from './pages/CV_Tandem'
import CVAtrato from './pages/CV_Atrato'
import CVLamin from './pages/CV_Lamin'
import CVHeroic from './pages/CV_Heroic'
import CVLuminai from './pages/CV_Luminai'
import CVRecallai from './pages/CV_Recallai'
import CVJustpaid from './pages/CV_Justpaid'
import CVStarlight from './pages/CV_Starlight.jsx'
import CVRosebudai from './pages/CV_Rosebudai.jsx'
import CVKontigo from './pages/CV_Kontigo.jsx'
import CVClassdojo from './pages/CV_Classdojo.jsx'
import CVEightsleep from './pages/CV_Eightsleep.jsx'
import CVAscen from './pages/CV_Ascen.jsx'
import CVHapi from './pages/CV_Hapi.jsx'

import CVDrillbit from  './pages/CV_Drillbit.jsx' 
import CVLegionfarm from './pages/CV_Legionfarm.jsx'
import CVKscale from './pages/CV_Kscale.jsx'
import CVSepalai from './pages/CV_Sepalai.jsx'
import CVFirstignite from './pages/CV_Firstignite.jsx'
import CVSilurian from './pages/CV_Silurian.jsx'
import CVGreywing from './pages/CV_Greywing.jsx'
import CVTerrace from './pages/CV_Terrace.jsx'
import CVJupiter from './pages/CV_Jupiter.jsx'
import CVFirecrawl from './pages/CV_Firecrawl.jsx'
import CVContalink from './pages/CV_Contalink.jsx'
import CVShuttle from './pages/CV_Shuttle.jsx'
import CVThreshold from './pages/CV_Threshold.jsx'
import CVJabu from './pages/CV_Jabu.jsx'
import CVIntryc from './pages/CV_Intryc.jsx'
import CVLumenalta from './pages/CV_Lumenalta.jsx'
import CVRallyUXR from './pages/CV_RallyUXR.jsx'
import CVHelpcare from './pages/CV_Helpcare.jsx'
import CVAshby from './pages/CV_Ashby.jsx'
import CVNango from './pages/CV_Nango.jsx'
import CVArcoPublicidad from './pages/CV_ArcoPublicidad.jsx'

import Demo from './pages/Demo.jsx'
import Cambioml from './pages/Cambioml.jsx'
import Appointment from './pages/Appointment'

export const TheRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home/>} />
            </Routes>
            <Routes>
                <Route path="/hardware" element={<Hardware/>} />
            </Routes>
            <Routes>
                <Route path="/schedule" element={<Appointment/>} />
            </Routes>

            <Routes>
                <Route path="/resume/shaped/docx" element={<CVShaped/>} />
                <Route path="/resume/&u$p/capi" element={<CVCapi/>} />
                <Route path="/resume/@w6&/bego" element={<CVBego/>} />
                <Route path="/resume/9@&$/bio" element={<CVBio/>} />
                <Route path="/resume/&8&@/live" element={<CVLive/>} />
                <Route path="/resume/1&&@/team" element={<CVTeam/>} />
                <Route path="/resume/3@$!/rappi" element={<CVRappi/>} />
                <Route path="/u!$!/resume" element={<CVInfi/>} />
                <Route path="/u1$!/resume" element={<CVTailor/>} />
                <Route path="/41$@/resume" element={<CVArtisan/>} />
                <Route path="/!6$@/resume" element={<CVTurntable/>} />
                <Route path="/!2$$/resume" element={<CVReady/>} />
                <Route path="/7!@$/resume" element={<CVCambioml/>} />
                <Route path="/!5$$/resume" element={<CVTypewise/>} />
                <Route path="/!*2*/resume" element={<CVFurtherai/>} />
                <Route path="/2@4*/resume" element={<CVAutarc/>} />
                <Route path="/8@!0/resume" element={<CVRelari/>} />
                <Route path="/@*@0/resume" element={<CVTrellis/>} />
                <Route path="/3*4@/resume" element={<CVWorkflow86/>} />
                <Route path="/1*@7/resume" element={<CVCapixai/>} />
                <Route path="/@00!/resume" element={<CVDodo/>} />
                <Route path="/@5!!/resume" element={<CVControlhub/>} />
                <Route path="/@!8*/resume" element={<CVLiterably/>} />
                <Route path="/5!*0/resume" element={<CVDreamcraft/>} />
                <Route path="/2*5)/resume" element={<CVCodecrafters/>} />
                <Route path="/@*3@/resume" element={<CVSyntra/>} />
                <Route path="/@@*1/resume" element={<CVKaya/>} />
                <Route path="/@!*!/resume" element={<CVRoeai/>} />
                <Route path="/@3*)/resume" element={<CVStackai/>} />
                <Route path="/*1*!/resume" element={<CVReveryai/>} />
                <Route path="/*@)!/resume" element={<CVTavus/>} />
                <Route path="/*@6)/resume" element={<CVHaystack/>} />
                <Route path="/!(*9/resume" element={<CVSkyways/>} />
                <Route path="/!1@*/resume" element={<CVDot/>} />
                <Route path="/!t@2/resume" element={<CVStorylane/>} />
                <Route path="/!h(1/resume" element={<CVVerdn/>} />
                <Route path="/!*h@/resume" element={<CVTandem/>} />
                <Route path="/$a5*/resume" element={<CVAtrato/>} />
                <Route path="/*z5$/resume" element={<CVLamin/>} />
                <Route path="/**z2/resume" element={<CVHeroic/>} />
                <Route path="/*(z1/resume" element={<CVLuminai/>} />
                <Route path="/(*1a/resume" element={<CVRecallai/>} />
                <Route path="/(1*b/resume" element={<CVJustpaid/>} />
                <Route path="/)a*3/resume" element={<CVStarlight/>} />
                <Route path="/(m*1/resume" element={<CVRosebudai/>} />
                <Route path="/!d1*/resume" element={<CVKontigo/>} />
                <Route path="/@y1*/resume" element={<CVClassdojo/>} />
                <Route path="/d@1*/resume" element={<CVEightsleep/>} />
                <Route path="/g*1*/resume" element={<CVAscen/>} />
                <Route path="/h!@*/resume" element={<CVHapi/>} />
                <Route path="/@ga8/resume" element={<CVDrillbit/>} />
                <Route path="/z*!8/resume" element={<CVLegionfarm/>} />
                <Route path="/!z1*/resume" element={<CVKscale/>} />
                <Route path="/*l1!/resume" element={<CVSepalai/>} />
                <Route path="/!3!*/resume" element={<CVFirstignite/>} />
                <Route path="/!a*f/resume" element={<CVSilurian/>} />
                <Route path="/a*b2/resume" element={<CVGreywing/>} />
                <Route path="/!g*@/resume" element={<CVTerrace/>} />
                <Route path="/g!5@/resume" element={<CVJupiter/>} />
                <Route path="/!!d@/resume" element={<CVFirecrawl/>} />
                <Route path="/4!d@/resume" element={<CVContalink/>} />
                <Route path="/@d@2/resume" element={<CVShuttle/>} />
                <Route path="/@z!2/resume" element={<CVThreshold/>} />
                <Route path="/!w@1/resume" element={<CVJabu/>} />
                <Route path="/3!)*/resume" element={<CVIntryc />} />
                <Route path="/*!)2/resume" element={<CVLumenalta />} />
                <Route path="/*3*(/resume" element={<CVRallyUXR />} />
                <Route path="/3*((/resume" element={<CVHelpcare />} />
                <Route path="/2((*/resume" element={<CVAshby />} />
                <Route path="/(1(*/resume" element={<CVNango />} />
                <Route path="/*-*/resume" element={<CVArcoPublicidad />} />
            </Routes>
        </Router> 
    )
}