import React from "react";
import { InlineWidget } from "react-calendly";

const Appointment = () => {
  return (
    <div
    style={{
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
    }}
  >
    <InlineWidget
      url="https://calendly.com/joseatolentinov/30min"
      styles={{
        width: "100%",
        height: "100%",
      }}
    />
  </div>

  );
};


export default Appointment;